import React, {Component, Fragment} from 'react';
import MainImage from '../../Assets/Images/PagoPredialesEncargado.jfif';
// import MainImage from '../../Assets/Images/DSC_3462.jpg';

class TaxPayments extends Component <{},{}>{
  render(){
    return (
        <Fragment>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12  col-lg-5 mt-3 mt-3">
                          <span className = "col-sm">
                            <p>
                              Nos encargamos del pago de prediales durante el contrato que se tenga con su inmueble.
                            </p>
                            <p>
                              Contamos con personal capacitado en el campo para asesorarlo en todo lo que usted necesite referente a su inmueble.
                            </p>
                          </span>
                    </div>
                    <div className="col-12  col-lg-7">
                        {/*<img className = "services-image img-fluid mb-5 w-75" src = {MainImage} alt = "not found"/>*/}
                    </div>
                </div>
            </div>
        </Fragment>
    );
  }
}

export default TaxPayments;
