import React, {Component, Fragment} from 'react';
import Contracts from './ContractsComponent/ContractsComponent';
import Documents from '../Shared/DocumentsComponent/DocumentsComponent';
import Navbar from '../Shared/LoggedNavbarComponent';
import {bindActionCreators} from "redux";
import {loadContracts, loadDocuments, loadPayments,showLoading} from "../../Redux/ActionTypes";
import {connect} from "react-redux";
import type {RouterHistory} from "react-router-dom";
import {withRouter} from 'react-router-dom';
import Repair from "./RepairComponent/RepairComponent";
import RequestsService from "../../Services/RequestsService";
import {toast} from "../../Services/AlertService";
import AuthService from "../../Services/AuthService";
import {URL} from '../../Utils/Config';
import {validate} from "../../Utils/Utilities";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import ModalComponent from '../Shared/ModalComponent';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type state = {
    mainLoad: boolean,
    currentContract: number,
    contractId: number,
    fatherFolder: string,
    currentPage: number,
}

type Props = {
    history: RouterHistory
};

class DirectoryComponent extends Component<Props, state> {

    state = {
        mainLoad: true,
        currentContract: 0,
        fatherFolder: '',
        contractId: 0,
        repairRequest: false,
        tenatName: '',
        currentPage: 1,
        folderId: 0,
        repairs: [],
        showLoading: true,
        subdirectory: false,
        folderStructure: [],
        selectedItem: undefined,
        fileSelected: undefined,
        contractCurrentPage: 0,
        isModalOpen: false,
        file: '',
        numPages: null,
        pageNumber: 1
    };

    owner = this.props.owner;

    logOut() {
        AuthService.removeLocalStorageItems();
        this.props.history.push({
            pathname: '/login_clients'
        });
    }


    uploadPublicServices(contract, files) {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file', file);
        });
        formData.append('model', JSON.stringify({contract}));
        RequestsService.uploadFile(formData, URL.publicServices).then(res => {
            toast('Documento agregado exitosamente.', 'success');
        }).catch(() => {
            toast('Intentelo nuevamente.', 'error');
        });
    }

    fetchContract(page = 0) {
        const id = AuthService.getLogin();
        if (!id) return this.logOut();
        console.log("fetch page ", page);
        this.props.loadContracts(id, this.props.owner, page + 1);
    }

    updatePageAndFetchContract(page = 0) {
        this.setState({contractCurrentPage: page});
        this.fetchContract(page);
    }

    componentDidMount() {
        this.fetchContract(this.state.contractCurrentPage);
        validate();
    }


    loadRoot() {
        this.setState(previousState => {
            return {
                ...previousState,
                mainLoad: true,
                currentPage: 1,
                fatherFolder: "",
                fatherFolderId: 0,
                currentFolder: 0,
                subdirectory: false,
                fileSelected: undefined,
                folderStructure: []
            };
        });
        this.props.showLoading(true);
        this.props.loadDocuments(this.state.currentContract);
    }

    nextPage = add => {
        this.setState((prev, actualProps) => {
            if (prev.currentPage + add <= 0 || prev.currentPage + add > this.props.pages) {
                return {...prev};
            }
            this.loadFromFolder(prev.currentFolder, prev.fatherFolder, prev.currentPage + add);
            return {...prev, currentPage: prev.currentPage + add};
        });
    };

    getRepairs() {
        this.setState({showLoading: true})
        RequestsService.getRepairs(this.state.contractId)
            .then(items => {
                const repairs = items.reduce((acc, e) => {
                    if (!acc) acc = []

                    if (!acc.some((e2) => e2.Cons_Encabezado === e.Cons_Encabezado)) {
                        acc.push(e)
                    }

                    return acc;
                }, []);

                repairs.sort((a, b) => (a.Cons_Encabezado > b.Cons_Encabezado) ? -1 : 1);

                this.setState({
                    repairs,
                    showLoading: false
                })
            })
            .catch(() => {
                this.setState({showLoading: false})
            })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!this.state.currentContract && this.props.contracts[0]) {
            this.setState({
                ...this.state,
                currentContract: this.props.contracts[0].contract_id,
                tenantName: this.props.contracts[0].tenant_name,
                contractId: this.props.contracts[0].contract_code
            }, () => {
                this.loadRoot();
                this.getRepairs();
            });
        }
    }

    loadFromFolder(folderId, name, page, parentId, spaceKey?, invRequest?) {
        this.setState(previousState => {
            return {...previousState, mainLoad: false, fatherFolder: name, currentFolder: folderId};
        }, () => {
            this.props.showLoading(true);
            this.props.loadDocuments(this.state.currentContract, folderId, page, this.state.contractId, parentId, spaceKey, invRequest);
        });
    }

    openDoc(file) {
        if (file.doc_type_id < -1) {
            this.setState({subdirectory: true});
        } else {
            let subdirectory = this.state.fileSelected && this.state.fileSelected.doc_type_id !== file.doc_type_id;
            this.setState({...this.state, subdirectory});
        }

        if (file.icon_type === "Folder") {
            if (!this.state.fileSelected) {
                this.state.folderStructure.push(file);
                this.setState({...this.state, fileSelected: file });
            }

            // To comeback from 'Fotos inventario' folder
            if (file.doc_type_id === 22 && this.state.fileSelected && this.state.fileSelected.doc_type_id === 22) {
                return this.loadRoot();
            }
            // To move between folders inside new inventory folders and level up
            let invRequest = null;
            let invSpace = null;
            if (file.doc_type_id < 0){
                if (this.state.fileSelected.doc_type_id === file.doc_type_id) {
                    file = this.state.folderStructure[this.state.folderStructure.length - 2];
                    this.setState({fileSelected: this.state.folderStructure[this.state.folderStructure.length - 2]});
                    this.state.folderStructure.pop();
                } else {
                    this.state.folderStructure.push(file);
                    this.setState({fileSelected: file});
                }
                if (file.doc_type_id === -3 || file.doc_type_id === -5) {
                    invRequest = file.entity_type;
                }
                if (file.doc_type_id === -4) {
                    invSpace = file.entity_type;
                }
            }

            return this.loadFromFolder(file.doc_type_id, file.name, 1, file.parent_id, invSpace, invRequest)
        } else {
            this.setState({isModalOpen: true, file: file, pageNumber: 1});
        }
    }

    goToPreviousPage = () => {
        if (this.state.pageNumber !== 1) this.setState({pageNumber: this.state.pageNumber - 1});
      };
    goToNextPage = () => {
        if (this.state.pageNumber !== this.state.numPages) this.setState({pageNumber: this.state.pageNumber + 1});
    };

    loadRepairDirectories() {
        return this.openDoc(this.state.fileSelected)
    }

    selectContract(item, contract, tenantName) {
        if (item !== this.state.currentContract) {
            this.setState({...this.state, currentContract: item, contractId: contract, tenantName}, () => {
                this.loadRoot()
            });
        }
    }

    repair = () => {
        this.setState({repairRequest: true})
    };
    cancelRepair = () => {
        this.setState({repairRequest: false})
    };

    onCreatedRepair() {
        this.cancelRepair();
        this.getRepairs();
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const {
            currentContract, repairRequest, fatherFolder, contractId, mainLoad,
            tenantName, currentPage, currentFolder, repairs, showLoading, subdirectory,
            contractCurrentPage, pageNumber, numPages, file
        } = this.state;
        const {contracts, documents, pages, photos, payments, loadPayments, history, contractTotalPages} = this.props;
        return (
            <Fragment>
                <Navbar action={this.logOut.bind(this)}/>
                <div className="container">
                    <div className="row pt-2">
                        <Contracts contractKey={currentContract}
                                   owner={this.owner}
                                   contracts={contracts}
                                   repair={this.repair}
                                   select={this.selectContract.bind(this)}/>
                    </div>
                    <br/>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Pagination>
                            <PaginationItem disabled={contractCurrentPage - 1 < 0}>
                                <PaginationLink first={"true"} href={"#"} onClick={(e) => {
                                    e.preventDefault();
                                    this.updatePageAndFetchContract(contractCurrentPage - 1);
                                }}
                                >Anterior</PaginationLink>
                            </PaginationItem>
                            {
                                Array(contractTotalPages).fill("", 0, contractTotalPages).map((p, page) => (
                                    <PaginationItem key={`pagination_${page}`} active={contractCurrentPage === page}>
                                        <PaginationLink href={"#"} onClick={(e) => {
                                            e.preventDefault();
                                            if (page === contractCurrentPage) return;
                                            this.updatePageAndFetchContract(page);
                                        }}>{page + 1}</PaginationLink>
                                    </PaginationItem>
                                ))
                            }
                            <PaginationItem disabled={contractCurrentPage + 1 === contractTotalPages}>
                                <PaginationLink last={"true"} href={"#"} onClick={(e) => {
                                    e.preventDefault();
                                    this.updatePageAndFetchContract(contractCurrentPage + 1);
                                }}
                                >Siguiente</PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </div>
                    <div className="row">
                        {!repairRequest &&
                        <Documents owner={this.owner}
                                   payments={payments}
                                   loadPayments={loadPayments}
                                   photos={photos}
                                   folder={fatherFolder}
                                   folderId={currentFolder}
                                   documents={documents}
                                   pages={pages}
                                   contract={contractId}
                                   uploadPublicServices={this.uploadPublicServices}
                                   currentPage={currentPage}
                                   nextPage={this.nextPage}
                                   loadRoot={this.loadRoot.bind(this)}
                                   openDoc={this.openDoc.bind(this)}
                                   mainLoad={mainLoad}
                                   repair={this.repair}
                                   contracts={contracts}
                                   repairs={repairs}
                                   history={history}
                                   showLoading={showLoading}
                                   showLoadingDocuments={this.props.loadingDocuments}
                                   subdirectory={subdirectory}
                                   loadRepairDirectories={this.loadRepairDirectories.bind(this)}
                        />
                        }
                        {repairRequest &&
                        <Repair
                            cancelRepair={this.cancelRepair}
                            onCreatedRepair={this.onCreatedRepair.bind(this)}
                            contract={contractId}
                            tenantName={tenantName}
                        />
                        }

                    </div>
                    {this.state.isModalOpen && <ModalComponent size="lg" header="Previsualizar" onClose={() => this.setState(s => ({...s, isModalOpen: false}))}
                      body={
                        <div className="d-flex flex-column align-items-center w-100">
                          {file.content_type.split('/')[0] !== 'image'?
                          <div className="PDF-viewer">
                                <div className="d-flex justify-content-between align-items-baseline mb-2">
                                <i
                                    className={`fa fa-step-backward mx-3`}
                                    style={pageNumber === 1?{opacity: 0.3}:{cursor: "pointer", opacity: 1}}
                                    onClick={this.goToPreviousPage}
                                    
                                />
                                    <label>Pagina {pageNumber} de {numPages}</label>
                                <i
                                    className={`fa fa-step-forward mx-3`}
                                    style={pageNumber === numPages?{opacity: 0.3}:{cursor: "pointer", opacity: 1}}
                                    onClick={this.goToNextPage}
                                />
                                </div>
                                <div className="bg-dark border border-dark border-2 p-4" style={{maxHeight: "600px", overflowY: "auto"}}>
                                    <Document
                                    file={file.content_path}
                                    loading="Cargando PDF..."
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    >
                                        
                                        <Page
                                            pageNumber={pageNumber}
                                        />
                                    </Document>
                                </div>
                          </div>:
                          <div style={{maxHeight: "500px", overflowY: "auto", border: "1px solid black"}}>
                            <span>
                                <img src={file.content_path} className="img-fluid" alt="" />
                            </span>
                          </div>}
                        </div>
                        }
                        footer={
                            <div className="d-flex justify-content-end align-content-center">
                                <a rel="noopener noreferrer" className="btn btn-success" target="_blank" href={file.content_path}>Descargar</a>
                            </div>
                        }
                    />}
                </div>
            </Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDocuments, showLoading, loadContracts, loadPayments}, dispatch);
}

function mapStateToProps(state) {
    const {documents, photos, contracts, payments, pages, loadingDocuments, contractTotalPages} = state.InformationReducer;
    return {documents, photos, contracts, payments, pages, loadingDocuments, contractTotalPages};
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DirectoryComponent));
