import React, {Component} from 'react';
import './Requirements.scss';
import FileList from "./FileList";


class Requirements extends Component <{},{}>{
  render(){
    return (
        <div className = "container">
          <div className = "row py-5">
            <div className = "col-12">
              <h2 className = "text-primary text-center text-ayu-bold">Requisitos para arrendar un inmueble</h2>
            </div>
          </div>
          {/*  <div className = "d-flex justify-content-center mb-4">*/}
          {/*    <div className = "requirements-card">*/}
          {/*      <h2 className = "text-ayu mb-4">$42.000</h2>*/}
          {/*      <p className = "text-ayu-size-md text-center">Valor del estudio por la agencia</p>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className = "row mb-4">
            <div className="col-2 d-none d-lg-block"/>
            <div className = "col-12 col-lg-4">
              <div className="text-primary text-center mb-3"><b>DOCUMENTOS TITULAR</b></div>
              <div>
                <b>EMPLEADO</b>
                <p>Carta laboral, RUT y fotocopia de cédula ampliada al 200%.</p>
                <b>PENSIONADO</b>
                <p>Colilla de pago actual, RUT y fotocopia de cédula ampliada al 200%.</p>
                <b>INDEPENDIENTE</b>
                <p>Fotocopia de cédula ampliada al 200%, RUT, cámara de comercio, certificado de ingresos, declaración de
                  renta y extractos bancarios.</p>
              </div>
            </div>
            <div className = "col-12 col-lg-4 ">
              <div className="text-primary text-center mb-3"><b>DOCUMENTOS COARRENDATARIO</b></div>
              <div>
                <b> COARRENDATARIO CON PROPIEDAD RAÍZ:</b>
                <p>Fotocopia de cédula ampliada al 200 %, impuesto predial y la propiedad no puede ser patrimonio o no
                  puede tener afectación de vivienda familiar, copia de servicios públicos. Si la propiedad esta ubicada
                  fuera del área metropolitana se firmará contrato con la aseguradora.</p>
                <b>COARRENDATARIO EMPLEADO</b>
                <p>Carta laboral, Fotocopia cédula ampliada al 200%.</p>
                <b>COARRENDATARIO INDEPENDIENTE</b>
                <p>Fotocopia de cédula ampliada al 200%, RUT, cámara de comercio, certificado de ingresos y extractos
                  bancarios.</p>
              </div>
            </div>
            <div className="col-2 d-none d-lg-block"/>
          </div>
          <div className = "row mb-4">
            <div className="col-2 d-none d-lg-block"/>
            <div className = "col-12 col-lg-4">
              <div className = "text-primary mb-3"><b>NOTA</b></div>
              <ul>
                <li className="text-justify">Ninguno de los solicitantes puede estar reportado a Data Crédito.</li>
                <li className="text-justify">Si alguno posee vehículo o motocicleta debe anexar la copia de la matrícula.</li>
                <li className="text-justify">Anexar fotocopia de los dos últimos recibos de pago de arrendamiento.</li>
                <li className="text-justify">Para facilitar el estudio de su solicitud, sea preciso con sus datos.</li>
                <li className="text-justify">Diligenciar totalmente la solicitud con huella y firma.</li>
              </ul>
              <p>
                De ser aprobada su solicitud, solo se entrega el inmueble una vez todos los contratantes hayan firmado y autenticado los documentos respectivos y cancelando canon de arrendamiento, mas el 20% de comisión, mas IVA de comisión, mas papelería.
                Tramitar esta solicitud, no implica compromiso por parte de ARRENDAMIENTOS AYURA S.A.S., de arrendar el inmueble al solicitante, consúltenos previamente sobre cualquier duda.
                Autorizo a ARRENDAMIENTOS AYURA S.A.S., para que los datos suministrados sean consultados en los bancos de datos.
                ARRENDAMIENTOS AYURA S.A.S., se reserva las causas en caso de no ser aceptada la solicitud
              </p>
            </div>
            <div className = "col-12 col-lg-4 files-card">
              <FileList/>
            </div>
            <div className="col-2 d-none d-lg-block"/>
          </div>
        </div>
    );
  }
}

export default Requirements;
